import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { navigate } from "gatsby"
import { Helmet } from "react-helmet"

function HouseCheck() {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const formtype = "HouseCheck"

  useEffect(() => {
    window.scrollTo(0, 0)
    //ga('send', 'event', {
    //  eventCategory: formtype, eventAction: 'Landed', eventLabel: formtype + 'Signup Form'
    //});
  }, [])

  const submitContactForm = event => {
    event.preventDefault()
    const url =
      "https://3o10wpxx9l.execute-api.us-west-2.amazonaws.com/default/isn-www-signup?type=" +
      formtype +
      "&name=" +
      name +
      "&email=" +
      email +
      "&phone=" +
      phone +
      "&message=" +
      message
    let encodedurl = encodeURI(url)
    fetch(encodedurl).then(navigate("/thankyou/"))
  }

  return (
    <div>
      <Helmet
        title="ISN HouseCheck Signup Form - Inspection Support Network"
        defer={false}
      />
      <Container>
        <Header activepage="| Contact" />
      </Container>
    <Container className={'legacy-page-spacer'} fluid>
        <Row
          style={{
            height: "250px",
            backgroundImage:
              'url("https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/cell-phone-in-hand-blurred-1030x425.jpg")',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></Row>
      </Container>
      <Container>
        <Row style={{ padding: "10px 0px" }}>
          <Col sm={12}>
            <hr />
            <h5 className="bold">ISN Sign up Form for {formtype}</h5>
            <br />
            <Form onSubmit={submitContactForm}>
              <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="email">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="phone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="phone"
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="message">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                />
              </Form.Group>
              <br />
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  )
}

export default HouseCheck
